import { useEffect, useMemo, useState } from 'react';
import CompanyService from '../services/company.service';
import { Company } from '../types';
import { hydrateCompanies } from '../utils';

export const useGetCompanies = (options?: {
  onlyPLClubs?: boolean;
  onlyClubsAndDefault?: boolean;
}) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isFetchingCompanies, setIsFetchingCompanies] =
    useState<boolean>(false);

  const companyService = useMemo(() => new CompanyService(), []);

  const fetchCompanies = async () => {
    setIsFetchingCompanies(true);
    const companies = await companyService.getCompanies({
      onlyPLClubs: options?.onlyPLClubs,
      onlyClubsAndDefault: options?.onlyClubsAndDefault,
    });

    const hydratedCompanies = (
      hydrateCompanies(companies.data) as Company[]
    )?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    setCompanies(hydratedCompanies as Company[]);
    setIsFetchingCompanies(false);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const refetchCompanies = () => {
    fetchCompanies();
  };

  return {
    companies,
    refetchCompanies,
    isFetchingCompanies: isFetchingCompanies,
  };
};
