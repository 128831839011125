import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, Space } from "antd";
import styled from "styled-components";
import { ClearOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import fieldToJSX from "../enums/fieldToJSX";
import { dateFilters, FilterField, TermListFilterEnum } from "../../types";
import dayjs from "dayjs";

const { Search } = Input;

const S_Space = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > :first-child {
    flex: 1;
  }
`;

const S_FilterForm = styled(Form)`
  margin-top: 24px;
  .ant-form-item-label > label {
    font-size: 12px;
  }
`;

const S_BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const S_FilterSearchBox = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
`;

export const FilterCard = ({
  filter,
  setFilter,
  filterFields,
  clearFilter,
  setSearchedText,
  layout,
}: {
  filter: any;
  setFilter: (filter: any) => void;
  filterFields: FilterField[];
  clearFilter?: () => void;
  setSearchedText?: (value: string) => void;
  layout?: { lg: number; md: number; sm: number; xs: number };
}) => {
  const [form] = Form.useForm();
  const [expanded, setExpanded] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>(
    (filter && filter.text) || "",
  );
  const [searchUserFriendly, setSearchUserFriendly] = useState("");

  const getFields = (layout?: {
    lg: number;
    md: number;
    sm: number;
    xs: number;
  }) => {
    const { lg, md, sm, xs } = layout;
    const children = [];
    for (let i = 0; i < filterFields.length; i++) {
      children.push(
        <Col lg={lg} md={md} sm={sm} xs={xs} key={i}>
          <Form.Item
            name={filterFields[i].name as string}
            label={filterFields[i].label}
          >
            {fieldToJSX(filterFields[i], true)}
          </Form.Item>
        </Col>,
      );
    }
    return children;
  };

  const onSearchText = (value: string) => {
    setSearchedText(value);
    setSearchInputValue(value);

    handleSearchUserFriendly(value);

    setFilter({
      ...filter,
      text: value,
    });
  };

  const handleSearchUserFriendly = (filter: any) => {
    let searchTerms;

    // Split the filter string using double quotes or spaces as delimiters
    if (filter.includes('"')) {
      searchTerms = filter.match(/(?:[^\s"]+|"[^"]*")+/g);
    } else {
      searchTerms = filter.split(" ");
    }

    // Remove duplicate search terms
    let uniqueSearch = [...new Set(searchTerms)];

    // Format the search terms for display
    setSearchUserFriendly(
      !uniqueSearch.join()
        ? ""
        : uniqueSearch.map((us) => `<b>${us}</b>`).join(" <u>OR</u> "),
    );
    // }
  };

  const onClearFilter = () => {
    setSearchInputValue("");
    clearFilter();
  };

  // console.log("in filter card", filter);

  const onApply = () => {
    let outp = { ...filter };
    for (let i = 0; i < filterFields.length; i++) {
      let value = form.getFieldValue(filterFields[i].name as string);

      // check if the filter value is empty string or empty array (for the 'type' filter)
      // and if it is, set it as undefined so the form updates correctly
      if (!value || value.length === 0) {
        value = undefined;
      }

      // TS will complain that you cannot assign a string value
      // to "state" and "type" because they are enums
      // @ts-ignore
      outp[filterFields[i].name] = value;
    }

    setFilter(outp);
  };

  //if filter is present on load, have the card expanded by default
  useEffect(() => {
    if (filter && Object.keys(filter).length > 0) {
      if (filter.text) handleSearchUserFriendly(filter.text);

      // format date filters with dayjs otherwise it would break
      // when setting filters from cached data, as they come as strings
      for (const filterKey in filter) {
        if (dateFilters.includes(filterKey as TermListFilterEnum)) {
          filter[filterKey] = filter[filterKey].map((date: Date) =>
            dayjs(new Date(date)),
          );
        }
      }

      form.setFieldsValue(filter);
      setExpanded(true);

      if (filter.title) {
        setSearchedText(filter.title);
      }
    } else {
      form.resetFields();
    }
  }, [filter]);

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onApply();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Card>
      <S_BodyWrapper>
        <S_Space>
          {setSearchedText ? (
            <S_FilterSearchBox>
              <Search
                style={{ flex: 1 }}
                placeholder="Text search"
                allowClear
                enterButton="Search"
                defaultValue={searchInputValue}
                onSearch={onSearchText}
              />
            </S_FilterSearchBox>
          ) : null}
          <Button type="link" onClick={() => setExpanded(!expanded)}>
            Filters
            {expanded ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </S_Space>
        {!!searchUserFriendly && (
          <span>
            Searching for:{" "}
            <span
              dangerouslySetInnerHTML={{ __html: searchUserFriendly }}
            ></span>
          </span>
        )}
      </S_BodyWrapper>
      {expanded && (
        <S_FilterForm
          layout="horizontal"
          labelCol={{ span: 8 }}
          labelWrap={true}
          form={form}
        >
          <Row gutter={48}>{getFields(layout)}</Row>

          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                {filter && Object.keys(filter).length > 0 && clearFilter && (
                  <Button
                    key={1}
                    onClick={onClearFilter}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                )}
                <Button type="primary" onClick={onApply}>
                  Apply
                </Button>
              </Space>
            </Col>
          </Row>
        </S_FilterForm>
      )}
    </Card>
  );
};
