export enum TermStatus {
  historic = 'historic',
  draft = 'draft',
  pending = 'pending',
  rejected = 'rejected',
  approved = 'approved',
  submitted = 'submitted',
  // todo restate state, they no longer want it but let's keep it just in case
  // restated = 'restated',
}

export enum TermType {
  databank = 'databank',
  threshold = 'threshold',
  associated = 'associated',
}

export enum UserRole {
  admin = 'admin',
  club = 'club',
  legal = 'legal',
  external = 'external',
  archived_legacy = 'archived_legacy',
  archived = 'archived',
}

export enum AttachmentType {
  default = 'default',
  redacted_agreement = 'redacted_agreement',
  unredacted_agreement = 'unredacted_agreement',
  apt_declarations = 'apt_declarations',
  pl_reports = 'pl_reports',
}

export enum PlayerTransactionType {
  threshold = 'threshold',
  associated = 'associated',
}
