import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "oidc-react";
import TransactionsList from "../pages/term/List";
import Dashboard from "../pages/Dashboard";
import TransactionForm from "../pages/term/Form";
import PlayerTransactionForm from "../pages/player-transaction/Form";
import UsersList from "../pages/user/List";
import NoFoundPage from "../pages/NoFoundPage";
import Unauthorized from "../pages/Unauthorized";
import Logout from "../pages/Logout";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import Loading from "@ant-design/pro-card/lib/components/Loading";
import Overview from "../pages/dashboard/Overview";
import { TransactionLayout } from "../pages/Transaction";
import { PlayerTransactionLayout } from "../pages/PlayerTransaction";
import { UserLayout } from "../pages/User";
import PlayerTransactionsList from "../pages/player-transaction/List";
import { UserRole } from "../enums";
import { JwTExpiryModalProvider } from "../components/modals/JwTExpiryModal";

export const AllRoutes = () => {
  const auth = useAuth();
  const { user, isFetchingUser } = useGetCurrentUser();

  const isAdmin = user.role === UserRole.admin;

  if (isFetchingUser || !user.email) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <JwTExpiryModalProvider />
      <Routes>
        <Route
          path="/"
          element={
            auth && !!auth.userData && !!user.email ? (
              <Navigate to="/transaction" />
            ) : (
              <Navigate to="/403" />
            )
          }
        />
        <React.Fragment>
          <React.Fragment>
            {isAdmin && (
              <>
                {/*<Route path="dashboard" element={<Dashboard />}>*/}
                {/*  <Route path="" element={<Overview />} />*/}
                {/*</Route>*/}

                {/*<Route*/}
                {/*  path="player-transaction"*/}
                {/*  element={<PlayerTransactionLayout />}*/}
                {/*>*/}
                {/*  <Route path="" element={<PlayerTransactionsList />} />*/}
                {/*  <Route path=":id" element={<PlayerTransactionForm />} />*/}
                {/*</Route>*/}

                <Route path="user" element={<UserLayout />}>
                  <Route path="" element={<UsersList />} />
                </Route>
              </>
            )}
            <Route path="transaction" element={<TransactionLayout />}>
              <Route path="" element={<TransactionsList />} />
              <Route path=":id" element={<TransactionForm />} />
            </Route>
            <Route path="*" element={<NoFoundPage />} />
          </React.Fragment>
          <Route path="/403" element={<Unauthorized />} />
          <Route path="logout" element={<Logout />} />
        </React.Fragment>
      </Routes>
    </BrowserRouter>
  );
};
