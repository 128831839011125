import { Avatar, Select } from "antd";
import React from "react";
import styled from "styled-components";
import { Company } from "../../types";

const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const getClubInputOptions = (clubs: Company[]) =>
  clubs.map((club) => (
    <Option key={club.value} value={club.value} display={club.display}>
      <SOptionLogoWrapper>
        <Avatar size="small" src={`/club_logos/48/${club.logo}`} alt="avatar" />
        {club.display}
      </SOptionLogoWrapper>
    </Option>
  ));

export default getClubInputOptions;
