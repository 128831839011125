import { TermType } from './enums';
import { AuditType, Company } from './types';

export const getLabelForTransactionType = (termType: TermType) => {
  switch (termType) {
    case TermType.threshold:
      return 'Threshold';
    case TermType.databank:
      return 'Databank';
    case TermType.associated:
      return 'Assoc. Party';
    default:
      return undefined;
  }
};

export const getLabelForAuditType = (auditType: AuditType) => {
  switch (auditType) {
    case AuditType.views:
      return 'Views';
    case AuditType.changes:
      return 'Changes';
    case AuditType.all:
      return 'All';
    default:
      return undefined;
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  if (!string) {
    return '';
  }

  return string?.length > 1
    ? string
        .split(' ')
        .map((s) => capitalize(s))
        .join(' ')
        .replace(/fc/gi, 'FC')
    : string.constructor === String
    ? capitalize(string)
    : '';
};

export const hydrateCompanies = (companies: Company | Company[]) => {
  // Convert a single company into an array if needed
  let companiesArray = Array.isArray(companies) ? companies : [companies];

  let outp = [];

  for (let i = 0; i < companiesArray.length; i++) {
    let item = {
      ...companiesArray[i],
      text: companiesArray[i]?.name,
      value: companiesArray[i]?.id,
      display: companiesArray[i]?.name,
      key: i,
    };
    outp.push(item);
  }

  // if it's an array of companies we're hydrating, return the array - otherwise the single company
  return Array.isArray(companies) ? outp : outp[0];
};

export const sortDataByTermTypeEnumOrder = (
  data: { type: TermType; count: number }[],
) => {
  const enumOrder = Object.values(TermType);

  return data?.sort((a, b) => {
    const indexA = enumOrder.indexOf(a.type);
    const indexB = enumOrder.indexOf(b.type);

    return indexA - indexB;
  });
};

export const formatFees = (fee: number) => Number(fee)?.toFixed(2);
