import React from "react";
import { Tag } from "antd";
import { TermType } from "../enums";

const TypeTag = ({ type }: { type: TermType }) => {
  const getDisplay = () => {
    switch (type) {
      case TermType.threshold:
        return "Threshold";
      case TermType.databank:
        return "Databank";
      case TermType.associated:
        return "Assoc. Party";
    }
  };

  const typeEnum = Object.fromEntries(
    Object.keys(TermType).map((type) => [
      [type],
      {
        color: "pink",
        display: getDisplay(),
      },
    ])
  );

  const typeResult = typeEnum[type] || {
    color: "blue",
    display: "n/a",
  };

  return <Tag color={typeResult.color}>{typeResult.display}</Tag>;
};

export default TypeTag;
