import React, { useEffect, useMemo, useState } from "react";

import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";
import styled from "styled-components";
import { Company, CompanyType, User } from "../../types";
import { UserRole } from "../../enums";
import { useGetCompanies } from "../../hooks/useGetCompanies";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import UserService from "../../services/user.service";
import TermService from "../../services/term.service";

const { Paragraph } = Typography;
const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const InviteUserModal = (props: {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onUserCreated: () => void;
}) => {
  const { user } = useGetCurrentUser();
  const userService = useMemo(() => new UserService(), []);
  const termService = useMemo(() => new TermService(), []);

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();
  const [selectedRole, setSelectedRole] = useState<UserRole>();
  const { isModalVisible, setIsModalVisible, onUserCreated } = props;
  const { companies } = useGetCompanies({ onlyPLClubs: true });

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(null);
    form.resetFields();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    form.validateFields().then((r) => form.submit());
  };

  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };
    saveData(values);
  };

  const saveData = async (data: any) => {
    setLoading(true);
    const hide = message.loading("Saving...", 1.0);

    const { firstName, lastName, companyId, shareExistingTransactions } = data;
    let payload = {
      ...user,
      newUser: {
        ...data,
        name: `${firstName} ${lastName}`,
        company: companies.find((company) => company.id === companyId),
      },
    };

    try {
      const newUserInvited = (await userService.createUser(payload)) as User;

      if (newUserInvited) {
        message.success("User successfully created");

        // todo if needed in the future, this logic needs to be fixed
        //  possibly using the new email rather than newUserInvited.id,
        //  cause at this stage we don't have the new id yet (needs to be synced)
        // if (shareExistingTransactions) {
        //   message.info("Sharing existing transactions...");
        //   try {
        //     const allTermsShared = await termService.shareAllTermsWithUser({
        //       companyId,
        //       userId: newUserInvited.id,
        //     });
        //
        //     if (allTermsShared) {
        //       message.success("All terms shared with new user");
        //     }
        //   } catch (err) {
        //     console.log(err);
        //   }
        // }
        onUserCreated();
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    hide();
  };

  useEffect(() => {
    // external companies can only have external users against them
    if (selectedCompany?.companyType === CompanyType.external) {
      form.setFieldValue("role", UserRole.external);
    } else {
      form.setFieldValue("role", selectedRole);
    }

    // club users can only be created against club companies
    if (
      selectedCompany?.companyType !== CompanyType.club &&
      form.getFieldValue("role") === UserRole.club
    ) {
      form.setFieldValue("role", undefined);
      form.getFieldInstance("role");
    }
  }, [selectedCompany?.id]);

  const findSelectedCompany = (companyId: string) => {
    const companyFound = companies.find((c) => c.id === companyId);

    if (companyFound) {
      setSelectedCompany(companyFound);
    }
  };

  return (
    <Modal
      title={`Invite User`}
      width={750}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" disabled={loading} onClick={handleCancel}>
          Cancel
        </Button>,

        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
        >
          Invite
        </Button>,
      ]}
    >
      <Paragraph>
        To invite a new user to the Commercial Databank, please provide the
        following details and submit. The user will receive a notification
        containing a link within 30 minutes of the invite.
      </Paragraph>
      <Form
        form={form}
        name="newTerm"
        layout="vertical"
        onFinish={onFormFinish}
        scrollToFirstError
      >
        <Form.Item
          name="companyId"
          label="Company"
          rules={[{ required: true }]}
        >
          <Select
            onSelect={findSelectedCompany}
            showSearch
            optionFilterProp="display"
            filterOption={(input, option) =>
              option.display.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {companies.map((company: Company) => (
              <Option
                key={company.id}
                value={company.value}
                display={company.display}
              >
                <SOptionLogoWrapper>
                  <Avatar
                    size="small"
                    src={`/club_logos/48/${company.logo}`}
                    alt="avatar"
                  />
                  {company.display}
                </SOptionLogoWrapper>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="role" label="Role" rules={[{ required: true }]}>
          <Select
            onSelect={setSelectedRole}
            disabled={selectedCompany?.companyType === CompanyType.external}
          >
            <Option key={1} value={UserRole.admin}>
              Admin
            </Option>
            <Option key={2} value={UserRole.legal}>
              Legal Team
            </Option>
            {selectedCompany?.companyType === CompanyType.club && (
              <Option key={3} value={UserRole.club}>
                Club
              </Option>
            )}
            {selectedCompany?.companyType === CompanyType.external && (
              <Option key={4} value={UserRole.external}>
                External
              </Option>
            )}
          </Select>
        </Form.Item>
        {/*todo Rebecca's request, temporarily removed */}
        {/*<Paragraph>*/}
        {/*  Do you want to share all existing transactions with the new user?*/}
        {/*</Paragraph>*/}
        {/*<Form.Item name="shareExistingTransactions" initialValue={true}>*/}
        {/*  <Select>*/}
        {/*    <Option value={true}>Yes</Option>*/}
        {/*    <Option value={false}>No</Option>*/}
        {/*  </Select>*/}
        {/*</Form.Item>*/}
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
