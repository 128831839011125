import React, { ReactNode, useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  FileOutlined,
  FilePptOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { UserRole } from "../enums";

const { Sider } = Layout;

const SSiderWrapper = styled.div`
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  z-index: 20;
  display: flex;
`;

const SLogo = styled.div<{ collapsed: string }>`
  display: flex;
  margin: 12px;
  justify-content: center;

  img {
    width: ${(props) => (props.collapsed === "true" ? "52px" : "128px")};
    height: auto;
  }
`;

type Route = {
  path: string;
  name: string;
  icon: ReactNode;
  isVisible?: boolean;
};
type SidebarItem = { key: string; icon: ReactNode; label: string };

const Sidebar = () => {
  const { user } = useGetCurrentUser();

  const isAdmin = user.role === UserRole.admin;
  const isPremierLeagueAdmin =
    user.role === UserRole.admin && user.company.name === "Premier League";

  const allRoutes: Route[] = [
    // {
    //   path: "dashboard",
    //   name: "Dashboard",
    //   icon: <DashboardOutlined />,
    //   isVisible: isPremierLeagueAdmin,
    // },
    {
      path: "transaction",
      name: "Transactions",
      icon: <FileOutlined />,
      isVisible: true,
    },
    // {
    //   path: "player-transaction",
    //   name: "Player Transactions",
    //   icon: <FilePptOutlined />,
    //   isVisible: isPremierLeagueAdmin,
    // },
    {
      path: "user",
      name: "Users",
      icon: <UserOutlined />,
      isVisible: isAdmin,
    },
  ];

  const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>();
  const [routes, setRoutes] = useState<Route[]>();
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const location = useLocation();

  const navigate = useNavigate();

  const createSidebarItems = (routes: Route[]) => {
    return routes
      .filter((route) => route.isVisible)
      .map((prop: Route, index: number) => {
        return {
          key: index.toString(),
          icon: prop.icon,
          label: prop.name,
        };
      });
  };

  useEffect(() => {
    // console.log("location", location);
    if (location && routes) {
      routes.forEach((route: Route, index: number) => {
        const locationPathname = location.pathname.split("/")[1];
        if (locationPathname === route.path) {
          setActiveKey(index.toString());
          return;
        }
      });
    }
  }, [location, routes]);

  useEffect(() => {
    if (user) {
      const routes = allRoutes.filter((r) => r.isVisible);
      const sidebarItems = createSidebarItems(routes);

      setSidebarItems(sidebarItems);
      setRoutes(routes);
    }
  }, [user]);

  const clickMenu = (menu: { key: string; keyPath: string[] }) => {
    // console.log(menu);
    setActiveKey(menu.key);
    navigate(`/${routes[parseInt(menu.key)].path}`);
  };

  return (
    <SSiderWrapper>
      <Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <SLogo id="logo" collapsed={collapsed.toString()}>
          {collapsed ? (
            <img src="/premierleague_small.png" alt="Premier League Logo" />
          ) : (
            <img src="/premierleague.png" alt="Premier League Logo" />
          )}
        </SLogo>
        <Menu
          defaultSelectedKeys={[activeKey]}
          selectedKeys={[activeKey]}
          onClick={clickMenu}
          mode="inline"
          items={sidebarItems}
        />
      </Sider>
    </SSiderWrapper>
  );
};

export default Sidebar;
