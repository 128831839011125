import React from "react";
import {
  Cascader,
  DatePicker,
  Radio,
  Select,
  Input,
  InputNumber,
  message,
} from "antd";
import {
  FilterField,
  PlayerTransactionFormField,
  TermFormField,
} from "../../types";

const { RangePicker } = DatePicker;

const cascaderSearch = (inputValue: string, path: string[]) =>
  path.some(
    (option: any) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  );

const fieldToJSX = (
  field: TermFormField | PlayerTransactionFormField | FilterField,
  canWrite?: boolean,
  canCopy?: boolean,
) => {
  let fieldContents;

  switch (field.type) {
    case "text":
      fieldContents = (
        <Input
          disabled={!canWrite}
          onCopy={(e) => {
            if (!canCopy) {
              e.preventDefault();
              message.error("Copying is not allowed.");
              return false;
            }
          }}
        />
      );
      break;
    case "textarea":
      fieldContents = (
        <Input.TextArea
          onCopy={(e) => {
            if (!canCopy) {
              e.preventDefault();
              message.error("Copying is not allowed.");
              return false;
            }
          }}
          showCount
          disabled={!canWrite}
          maxLength={field.maxLength}
        />
      );
      break;
    case "date":
      fieldContents = <DatePicker disabled={!canWrite} />;
      break;
    case "daterange":
      fieldContents = <RangePicker />;
      break;
    case "select":
      fieldContents = (
        <Select
          mode={field.select.mode}
          allowClear
          disabled={!canWrite}
          style={{ width: "100%" }}
          placeholder="Please select"
        >
          {field.select.options}
        </Select>
      );
      break;
    case "cascader":
      fieldContents = (
        <Cascader
          changeOnSelect
          disabled={!canWrite}
          options={field.cascader.options}
          placeholder="Please select"
          // @ts-ignore
          showSearch={cascaderSearch}
        />
      );
      break;
    case "radio":
      fieldContents = (
        <Radio.Group disabled={!canWrite}>
          {field.options.map((option: any, index: any) => (
            <Radio key={index} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      );
      break;
    case "number":
      fieldContents = (
        <InputNumber
          onCopy={(e) => {
            if (!canCopy) {
              e.preventDefault();
              message.error("Copying is not allowed.");
              return false;
            }
          }}
          style={{ width: "100%" }}
          disabled={!canWrite}
          placeholder={field?.placeholder}
          min={field.name === "number" ? 1000100 : null} // this is the tracking number
          formatter={
            field.name !== "number" && field.number?.formatter
              ? field.number.formatter
              : null
          }
          parser={
            field.name !== "number" && field.number?.parser
              ? field.number.parser
              : null
          }
        />
      );
      break;
    default:
      fieldContents = (
        <Input
          disabled={!canWrite}
          style={{ background: "red" }}
          onCopy={(e) => {
            if (!canCopy) {
              e.preventDefault();
              message.error("Copying is not allowed.");
              return false;
            }
          }}
        />
      );
      break;
  }

  return fieldContents;
};

export default fieldToJSX;
