import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from "antd";

import styled from "styled-components";
import { Company, User } from "../../types";
import { TermType, UserRole } from "../../enums";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import { useGetCompanies } from "../../hooks/useGetCompanies";
import TermService from "../../services/term.service";
import UserService from "../../services/user.service";

const { Paragraph, Link } = Typography;
const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

const DraftTransactionModal = ({
  isModalVisible,
  setIsModalVisible,
  newDraftType,
}: {
  isModalVisible: boolean;
  newDraftType: TermType;
  setIsModalVisible: (isVisible: boolean) => void;
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const userService = useMemo(() => new UserService(), []);

  const [loading, setLoading] = useState(false);
  const [sharing, setSharing] = useState(false);
  const { companies } = useGetCompanies({
    onlyPLClubs: true,
    onlyClubsAndDefault: true,
  });

  const [users, setUsers] = useState<User[]>([]);
  const { user } = useGetCurrentUser();
  const termService = useMemo(() => new TermService(), []);

  const isThreshold = newDraftType === TermType.threshold;
  const isAssociatedParty = newDraftType === TermType.associated;

  const fetchUsers = async (selectedCompanyId?: string) => {
    const sameCompanyUsers = await userService.getUsers(
      `/?$filter=(companyId eq ${user.company.id} ${
        selectedCompanyId ? `or companyId eq ${selectedCompanyId}` : ""
      }) and id ne ${user.id}`,
    );

    setUsers(sameCompanyUsers.data);

    const sharableUsersId = sameCompanyUsers.data.map((u: User) => u.id);

    form.setFieldValue("sharedWithUsers", sharableUsersId);
  };

  useEffect(() => {
    form.resetFields();
    setSharing(false);
  }, []);

  const saveData = async (data: any) => {
    setLoading(true);

    const hide = message.loading("Saving...", 1.0);

    const { sharedWithUsers, ...restOfInput } = data;

    const input = {
      ...restOfInput,
      // if it's admin, there will be data.company - otherwise it's the user's own company
      company: data.company || user.company.id,
      type: isThreshold
        ? TermType.threshold
        : isAssociatedParty
        ? TermType.associated
        : TermType.databank,
    };
    // console.log("input", input);

    try {
      const termCreated = await termService.createTerm({
        ...input,
      });

      if (termCreated) {
        message.success("Submitted", 2.5);
        if (sharing && sharedWithUsers.length > 0) {
          try {
            const termUpdated = await termService.updateTermSharedWith({
              id: termCreated.id,
              sharedWith: sharedWithUsers,
            });

            if (termUpdated) {
              message.success(
                `Transaction shared with ${sharedWithUsers.length} user(s)`,
                2.5,
              );
            }
          } catch (err) {
            message.error("Error", 2.5);
            setLoading(false);
            throw Error("There was a problem during the sharing operation.");
          }
        }
        hide();
        setLoading(false);
        navigate(`/transaction/${termCreated.id}`);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const onFormFinish = (fieldValues: any) => {
    const values = {
      ...fieldValues,
    };

    saveData(values);
  };

  const handleOk = () => {
    form.validateFields().then(() => form.submit());
  };
  const handleCancel = () => {
    setLoading(false);
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (user && user.company && user.company.id) {
      fetchUsers();
    }
  }, [user]);

  return (
    companies && (
      <Modal
        title={`Create Draft ${
          isThreshold
            ? "Threshold"
            : isAssociatedParty
            ? "Associated Party"
            : "Databank"
        } Transaction`}
        width={750}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,

          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Create Draft
          </Button>,
        ]}
      >
        {isThreshold ? (
          <>
            <Paragraph>
              Please select this option if submitting a new Threshold
              Transaction (please refer to the Premier League Rules for the full
              definitions but in summary, this includes any Transaction with a
              Third Party (that is not an Associated Party), the Averaged Annual
              Value of which exceeds £1 million).
            </Paragraph>

            <Paragraph>
              If you have any queries or are unsure of which option to select
              please contact the League via{" "}
              <Link href="mailto:financialcontrols@premierleague.com">
                financialcontrols@premierleague.com
              </Link>
            </Paragraph>
          </>
        ) : isAssociatedParty ? (
          //associated party description
          <>
            <Paragraph>
              Please select this option if submitting a new Associated Party
              Transaction (please refer to the Premier League Rules for the full
              definitions but in summary, this applies to any Transaction of any
              value that is directly or indirectly between: (1) a Club, Player,
              Manager or Senior Official; and (2) an Associated Party of the
              Club).
            </Paragraph>

            <Paragraph>
              If you have any queries or are unsure of which option to select
              please contact the League via{" "}
              <Link href="mailto:financialcontrols@premierleague.com">
                financialcontrols@premierleague.com
              </Link>
            </Paragraph>
          </>
        ) : (
          //databank description
          <>
            <Paragraph>
              Please select this option if submitting a new Databank Transaction
              (please refer to the Premier League Rules for the full definitions
              but in summary, any Transaction between the Club and a third party
              pursuant to which a payment, fee or value in kind is provided or
              to be provided to the Club, with an Averaged Annual Value between
              £100,000 and £1 million per annum).
            </Paragraph>

            <Paragraph>
              Any new Transaction worth over £1m per annum should be submitted
              as a Threshold Transaction.
            </Paragraph>

            <Paragraph>
              If you have any queries or are unsure of which option to select
              please contact the League via{" "}
              <Link href="mailto:financialcontrols@premierleague.com">
                financialcontrols@premierleague.com
              </Link>
            </Paragraph>
          </>
        )}
        <Form
          form={form}
          name="newTerm"
          layout="vertical"
          onFinish={onFormFinish}
          scrollToFirstError
        >
          {user.role === UserRole.admin && (
            <Form.Item
              name="company"
              tooltip="Select a company this term is on behalf of."
              label="Company"
              rules={[{ required: true }]}
            >
              <Select
                onSelect={(e) => fetchUsers(e)}
                showSearch
                placeholder="Search to Select"
                optionFilterProp="display"
                filterOption={(input, option) =>
                  option.display.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {companies?.map((company: Company, index: number) => (
                  <Option
                    key={index}
                    value={company.value}
                    display={company.display}
                  >
                    <SOptionLogoWrapper>
                      <Avatar
                        size="small"
                        src={`/club_logos/48/${company.logo}`}
                        alt="avatar"
                      />
                      {company.display}
                    </SOptionLogoWrapper>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <Input.TextArea showCount maxLength={1000} />
          </Form.Item>
          {/*todo Rebecca's request, temporarily removed */}
          {/*<Paragraph>*/}
          {/*  Do you want to share this transaction with other users of your Club?*/}
          {/*</Paragraph>*/}
          {/*<Flex style={{ flex: "auto", paddingBottom: "10px" }}>*/}
          {/*  <Select*/}
          {/*    style={{ width: "100%" }}*/}
          {/*    defaultValue={false}*/}
          {/*    onSelect={(e) => setSharing(e)}*/}
          {/*  >*/}
          {/*    <Option value={true}>Yes</Option>*/}
          {/*    <Option value={false}>No</Option>*/}
          {/*  </Select>*/}
          {/*</Flex>*/}
          {/*{sharing && (*/}
          {/*  <Form.Item*/}
          {/*    name="sharedWithUsers"*/}
          {/*    tooltip="Select users to share this transaction with."*/}
          {/*    label="Users"*/}
          {/*    // only required if user is sharing the new transaction*/}
          {/*    rules={[{ required: sharing }]}*/}
          {/*  >*/}
          {/*    <Select*/}
          {/*      filterOption={(input, option) =>*/}
          {/*        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
          {/*      }*/}
          {/*      mode="multiple"*/}
          {/*      placeholder="Please select"*/}
          {/*      style={{ width: "100%" }}*/}
          {/*      options={users.map((user) => ({*/}
          {/*        value: user.id,*/}
          {/*        label: user.name,*/}
          {/*      }))}*/}
          {/*    />*/}
          {/*  </Form.Item>*/}
          {/*)}*/}
        </Form>
      </Modal>
    )
  );
};

export default DraftTransactionModal;
